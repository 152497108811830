import { Main } from "./style";
import User from "../../../assets/images/svgs/login/Layer_1.svg";
import Password from "../../../assets/images/svgs/login/Vector.svg";
import { useForm } from "react-hook-form";
import { auth } from "../../../firebase";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { db } from "../../../firebase"; // Import your Firestore instance
import { doc, getDoc, setDoc } from "firebase/firestore"; // Import Firestore functions
import { GoEye, GoEyeClosed } from "react-icons/go";
import Logo from "../../../assets/images/svgs/logo/Logo.svg";
const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [firebaseError, setFirebaseError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const Register = (state) => {
    navigate("/auth/register", { state });
  };
  const forget = (state) => {
    navigate("/auth/forget-password", { state });
  };

  const onSubmit = async (data) => {
    const { email, password } = data;

    setFirebaseError(null);

    try {
      setLoading(true); // Start loading
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log("Current User UID:", user.uid);
      localStorage.setItem("UID", user.uid);

      // Check if email is verified
      if (!user.emailVerified) {
        // Send email verification if not already verified
        await sendEmailVerification(user);
        toast.error(
          "Your email is not verified. Please check your inbox to verify your email.",
          {
            position: "top-right",
            autoClose: 5000,
          }
        );
        setLoading(false);
        return; // Prevent login if email is not verified
      }

      // Store the token in localStorage
      localStorage.setItem("token", user.accessToken);

      const userRef = doc(db, "users", user.uid); // Reference to the user document
      const userSnapshot = await getDoc(userRef); // Fetch user data

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data(); // Get user data
        console.log("Username:", `${userData.firstName} ${userData.lastName}`); // Log full name
        console.log("User Photo:", userData.photo); // Log photo URL

        localStorage.setItem("username", userData.firstName);
        localStorage.setItem("userPhoto", userData.photo);
      } else {
        console.log("No such user document!");
      }

      // Notify the user about the successful login
      toast.success(`Welcome back ${user.email}!`, {
        position: "top-right",
        autoClose: 3000,
      });

      // Optionally, save user login data to Firestore
      // await setDoc(doc(db, "users", user.uid), {
      //   email: user.email,
      //   lastLogin: new Date().toISOString(), // Store the last login time
      // });

      // After login, navigate to the dashboard
      navigate("/");
    } catch (error) {
      // Handle specific Firebase errors
      let errorMessage = "Login failed. Please try again.";
      if (error.code === "auth/user-not-found") {
        errorMessage = "No user found with this email.";
      } else if (error.code === "auth/wrong-password") {
        errorMessage = "Incorrect password. Please try again.";
      } else if (error.code === "auth/invalid-email") {
        errorMessage = "Invalid email format.";
      }

      setFirebaseError(errorMessage);
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setLoading(false); // End loading
    }
  };
  //password show
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Main>
      <ToastContainer />
     
      <form onSubmit={handleSubmit(onSubmit)} className="form-padding">
      <div className="logo">
        <img src={Logo} alt="logo" />
      </div>
        <p className="welcome-text">Login to your account</p>
        <p className="welcome-text2">
          Please enter your registered name and email below to login to your
          account.
        </p>

        <label className="label">Enter Your Email <span className="staric">*</span></label>
        <div className="input-container">
          <img src={User} alt="user" className="input-icon" />
          <input
            type="email"
            className="input"
            placeholder="Enter your Email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Enter a valid email",
              },
            })}
          />
        </div>
        {errors.email && (
          <p className="error-message">{errors.email.message}</p>
        )}

        <label className="label">Enter Your Password <span className="staric">*</span></label>
        <div className="input-container" style={{ position: "relative" }}>
          <img src={Password} alt="password" className="input-icon" />
          <input
            type={showPassword ? "text" : "password"} // Change type based on showPassword state
            className="input"
            placeholder="Enter your Password"
            {...register("password", { required: "Password is required" })}
          />
          <div
            onClick={togglePasswordVisibility}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "15px",
              top: "15px",
            }}
          >
            {showPassword ? <GoEye size={20} /> : <GoEyeClosed size={20} />}
          </div>
        </div>
        {errors.password && (
          <p className="error-message">{errors.password.message}</p>
        )}
        <div
          className="text-end"
          style={{ marginBottom: "10px" }}
          onClick={() => forget()}
        >
          <p className="m-0 forget-password">Forget Password?</p>
        </div>
        {firebaseError && <p className="error-message">{firebaseError}</p>}

        {/* <div className="text-end">
          <p className="register" onClick={() => Register()}>
            Register Here{" "}
          </p>
        </div> */}

        <div style={{ textAlign: "center" }}>
          <button
            type="submit"
            className="button"
            disabled={loading}
            style={{ marginBottom: "20px" }}
          >
            {loading ? "Logging in..." : "LOGIN"}
          </button>

          <button
            type="button"
            className="already-button"
            onClick={() => Register()}
          >
            Register Here
          </button>
        </div>
      </form>
    </Main>
  );
};

export default Login;
