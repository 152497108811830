import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "../components/layouts/auth-layout";
import MainLayout from "components/layouts/main-layout";
import ProtectedRoute from "routes/protected";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Main Application Routes */}
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        />
        {/* Auth routes */}
        <Route
          path="/auth/*"
          element={<AuthLayout />}
        />
      </Routes>
    </BrowserRouter>
    
  );
};

export default AppRoutes;