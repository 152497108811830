import styled from "styled-components";


export const Main = styled.div`

.line {
    border-bottom: 1px solid rgba(216, 216, 216, 1);
    margin-top: 70px;
  }
  .milap-text {
    font-family: "Lato";
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
    margin: 0px;
    margin-bottom: 20px;
  }



`