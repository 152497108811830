//  eslint-disable prettier/prettier

// import AuthRoutes from "../../../containers/auth/index";
// import { StyledMain } from "./style";
// import Splash from "../../../assets/images/others/login/splash.png";
// const AuthLayout = () => {
//   return (
//     <StyledMain>
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-lg-6 d-none d-lg-block p-0 h-100">
//             <img
//               src={Splash}
//               alt="Login Image"
//               className="w-100 "
//               style={{ height: "100vh" }}
//             />
//           </div>
//           <div className="col-lg-6 col-12 login-background p-0">
//             <AuthRoutes />
//           </div>
//         </div>
//       </div>
//     </StyledMain>
//   );
// };

// export default AuthLayout;

import AuthRoutes from "../../../containers/auth/index";
import { StyledMain } from "./style";
import Splash from "../../../assets/images/others/login/splash.png";
import { useLocation } from "react-router-dom";

const AuthLayout = () => {
  const location = useLocation();
  return (
    <StyledMain>
      <div className="container-fluid">
        <div className="row">
          {/* {location.pathname !== "/auth/verify" && ( // Check if the current path is not '/auth/verify'
            <div className="col-lg-6 d-none d-lg-block p-0 h-100">
              <div className="fixed-image-container">
                <img
                  src={Splash}
                  alt="Login Image"
                  className="fixed-splash-image"
                />
              </div>
            </div>
          )}
          <div className="col-lg-6 col-12 login-background p-0">
            <AuthRoutes />
          </div> */}
           {/* {location.pathname !== "/auth/verify" && ( // Check if the current path is not '/auth/verify' */}
            <div className="col-lg-6 d-none d-lg-block p-0 h-100">
              <div className="fixed-image-container">
                <img
                  src={Splash}
                  alt="Login Image"
                  className="fixed-splash-image"
                />
              </div>
            </div>
          {/* )} */}
          {/* {location.pathname !== "/auth/verify" ? ( // Only render this column if the route is not '/auth/verify' */}
            <div className="col-lg-6 col-12 login-background p-0">
              <AuthRoutes />
            </div>
          {/* ) : ( */}
            {/* <div className="col-12 login-background p-0">
              <AuthRoutes /> {/* Keep this to maintain layout consistency */}
            {/* </div>  */}
          {/* )} */}
        </div>
      </div>
    </StyledMain>
  );
};

export default AuthLayout;
