// In this file, you will be putting the styling which you are going to use globally in your application which leads to the reusability of the styling

import { createGlobalStyle } from 'styled-components';
import Arrow from '../assets/images/others/select/arrow-select.png'

export default createGlobalStyle`

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :root{
        --primary: #41e0a3;

        //put your global colors here which you are using multiple times in your application.
        /*  You can replace the primary color with your main theme color */
    }
    select {
    appearance: none; /* Remove default arrow */
    -webkit-appearance: none; /* Remove default arrow for Safari */
    -moz-appearance: none; /* Remove default arrow for Firefox */
    background: url(${Arrow}) no-repeat right 10px center;
    background-size: 12px; /* Adjust the size as needed */
    padding-right: 30px; /* Ensure space for the arrow */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    color: #333;
  }
  
  /* Optional: Adjust hover and focus styles */
  select:focus {
    outline: none;
    border-color: var(--primary);
  }
  p{
    margin: 0;
  }
  .staric{
    color: red;
  }
`;