import React, { useState } from "react";
import { Forget } from "./style";
import { useForm } from "react-hook-form";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/svgs/logo/Logo.svg";


const ForgetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate()

  const Login = (state) => {
    navigate("/auth", { state });
  };

  const onSubmit = async (data) => {
    const auth = getAuth(); // Get Firebase auth instance
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, data.email); // Send password reset email
      toast.success("Password reset email sent! Check your inbox."); // Show success toast
    } catch (error) {
      toast.error("Error sending email. Please check your email address."); // Show error toast
    } finally {
      setLoading(false);
    }
  };
  return (
    <Forget>
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)} className="form-padding">
      <div className="logo">
        <img src={Logo} alt="logo" />
      </div>
        <p className="welcome-text">Reset Your Password</p>
        <p className="welcome-text2">
          Please enter your registered email below to receive a password reset
          link.
        </p>

        <label className="label">Enter Your Email</label>
        <div className="input-container">
          <input
            type="email"
            className="input"
            placeholder="Enter your Email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Enter a valid email",
              },
            })}
          />
        </div>
        {errors.email && (
          <p className="error-message">{errors.email.message}</p>
        )}

        <div style={{ textAlign: "center" }}>
          <button
            type="submit"
            className="button"
            disabled={loading}
            style={{ marginBlock: "20px" }}
          >
            {loading ? "Sending..." : "SEND RESET LINK"}
          </button>
          <button
            type="submit"
            className="already-button"
            onClick={()=>Login()}
            style={{ marginBottom: "20px" }}
          >
            {/* {loading ? "Sending..." : "SEND RESET LINK"} */}
            LOGIN
          </button>
        </div>

        {message && <p className="info-message">{message}</p>}
      </form>
    </Forget>
  );
};

export default ForgetPassword;
