import { firestore, storage } from "../../../firebase";
import {
  addDoc,
  collection,
  getDoc,
  doc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";

import { toast, ToastContainer } from "react-toastify";
import Image from "../../../assets/images/svgs/register/OBJECTS.svg";
import { BottomCards, Main } from "../home/style";
import statesData from "./countries";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

const MissingPersons = () => {
  const myId = localStorage.getItem("UID");
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const [photo, setPhoto] = useState(null);
  const [upload, setUpload] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const location = useLocation();
  const validateFile = (file) => {
    const validTypes = ["image/jpeg", "image/png"];
    if (file) {
      if (!validTypes.includes(file.type)) {
        setError("photo", {
          type: "manual",
          message: "Only .png, .jpg, or .jpeg files are accepted.",
        });
        setPhoto(null);
      } else {
        const filePath = URL.createObjectURL(file);
        clearErrors("photo");
        setUpload(filePath);
        setPhoto(file);
      }
    }
  };

  useEffect(() => {
    if (id) {
      console.log("update");
    } else {
      console.log("missing persons");
    }
  }, [id]); // Run effect when id changes
  useEffect(() => {
    // Check if the current path is for adding new data
    if (location.pathname === "/missing-persons") {
      reset(); // Reset the form when navigating to the add new data route
      setUpload("");
      setSelectedCountry("");
      setSelectedCity("");
      setSelectedState("");
    }
  }, [location.pathname, reset]);
 
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const { fullName, country, state, city, location, relationship, story } =
        data;

      // Ensure at least one photo is present
      if (!photo && !upload) {
        throw new Error("No photo selected.");
      }

      let photoUrl;
      if (photo) {
        // Only upload if a new photo is provided
        const photoRef = ref(storage, `missing-persons/${photo.name}`);
        await uploadBytes(photoRef, photo);
        photoUrl = await getDownloadURL(photoRef);
      } else {
        photoUrl = upload; // Use existing photo URL
      }

      // Check if we're adding a new document or updating an existing one
      if (id) {
        // Update existing document
        const docRef = doc(firestore, "missing persons", id);
        await updateDoc(docRef, {
          fullName,
          country,
          state,
          city,
          location,
          relationship,
          story,
          photo: photoUrl,
          updatedBy: myId, // Optional: Track who updated the record
        });

        toast.success("Missing person details updated successfully!", {
          position: "top-right",
          autoClose: 4000,
        });
      } else {
        // Add new document
        const newDocRef = doc(collection(firestore, "missing persons"));
        await setDoc(newDocRef, {
          fullName,
          country,
          state,
          city,
          location,
          relationship,
          story,
          photo: photoUrl,
          createdBy: myId, // Optional: Track who created the record
          createdAt: new Date(), // Timestamp for when the record was created
        });

        toast.success("Missing person added successfully!", {
          position: "top-right",
          autoClose: 4000,
        });
      }

      
      setTimeout(() => {
        window.location.href = "/";  
      }, 4000);
    } catch (error) {
      console.error("Error processing missing person: ", error);
      toast.error(
        `Failed to process missing person details: ${error.message}`,
        {
          position: "top-right",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCountryChange = (e) => {
    debugger
    const countryName = e.target.value;
    setSelectedCountry(countryName);
    setSelectedState(""); // Clear state selection
    setCities([]); // Clear cities when country changes

    const selectedCountryData = statesData.find(
      (country) => country.name === countryName
    );

    if (selectedCountryData) {
      setStates(selectedCountryData.states);
    } else {
      setStates([]);
    }
    setValue("country", countryName, { shouldValidate: true });
  };
  

  const handleStateChange = (e) => {
    const stateId = e.target.value;
    setSelectedState(stateId);

    const selectedStateData = states.find(
      (state) => state.id === parseInt(stateId)
    );

    if (selectedStateData) {
      setCities(selectedStateData.cities);
      // setValue("city", ""); // Clear city selection
      setValue("city", "", { shouldValidate: true });
    } else {
      setCities([]);
    }
    setValue("state", stateId, { shouldValidate: true });
  };
  useEffect(() => {
    if (id) {
      const fetchMissingPersonData = async () => {
        const docRef = doc(firestore, "missing persons", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log("Fetched data:", data); // Log fetched data

          // Prefill form with existing data
          setValue("fullName", data.fullName);
          setValue("country", data.country);
          setValue("state", data.state);
          setValue("city", data.city);
          setSelectedCity(data.city);

          setValue("location", data.location);
          setValue("relationship", data.relationship);
          setValue("story", data.story);
          setUpload(data.photo);

          // Set selected country
          const country = data.country;
          setSelectedCountry(country);

          const selectedCountryData = statesData.find(
            (countryData) => countryData.name === country
          );

          if (selectedCountryData) {
            setStates(selectedCountryData.states);

            const selectedStateId = data.state;
            setSelectedState(selectedStateId);

            const selectedStateData = selectedCountryData.states.find(
              (state) => state.id === parseInt(selectedStateId)
            );

            if (selectedStateData) {
              setCities(selectedStateData.cities);
              setValue("city", data.city); // Make sure this matches what's in cities
            }
          }
        } else {
          console.log("No such document!");
        }
      };
      fetchMissingPersonData();
    }
  }, [id, setValue]);

  

  return (
    <Main>
      <ToastContainer />
      <BottomCards className="m-0">
        <div
          className="text-center missing-persons"
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   height: "90vh",
          //   flexDirection: "column",
          // }}
        >
          <p className="edit-text">
            {id
              ? "Update missing person details"
              : "Enter missing person details"}
          </p>
          <form className="card form" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <input
                type="text"
                className="input"
                placeholder="Full name"
                {...register("fullName", {
                  required: "Full name is required",
                })}
              />
              {errors.fullName && (
                <p className="error">{errors.fullName.message}</p>
              )}
            </div>

            <div
              className="location-flex"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "30%" }}>
                <select
                  className="input"
                  {...register("country", { required: "Country is required" })}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  <option value="">Country</option>
                  {statesData.map((country) => (
                    <option key={country.id} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <p className="error">{errors.country.message}</p>
                )}
              </div>
              <div style={{ width: "30%" }}>
                <select
                  className="input"
                  {...register("state", { required: "State is required" })}
                  value={selectedState}
                  onChange={handleStateChange}
                >
                  <option value="">State</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <p className="error">{errors.state.message}</p>
                )}
              </div>
              <div style={{ width: "30%" }}>
                <select
                  value={selectedCity}
                  className="input"
                  {...register("city", { required: "City is required" })}
                  onChange={(e) => {
                    const cityName = e.target.value;
                    setSelectedCity(cityName);
                    setValue("city", cityName, { shouldValidate: true }); // Update react-hook-form's city value
                  }}
                >
                  <option value="">City</option>
                 
                  {cities.map((city) => (
                    <option key={city.id} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
                {errors.city && <p className="error">{errors.city.message}</p>}
              </div>
            </div>
         

            <div style={{ marginTop: "10px" }}>
              <input
                type="text"
                className="input"
                placeholder="Location"
                {...register("location", { required: "Location is required" })}
              />
              {errors.location && (
                <p className="error">{errors.location.message}</p>
              )}
            </div>

            <div style={{ marginTop: "10px" }}>
              <select
                className="input"
                {...register("relationship", {
                  required: "Relationship is required",
                })}
              >
                <option value="">Relationship</option>
                <option value="parent">Parent</option>
                <option value="sibling">Sibling</option>
                <option value="child">Child</option>
                <option value="friend">Friend</option>
                <option value="other">Other</option>
                {/* Add more relationship options */}
              </select>
              {errors.relationship && (
                <p className="error">{errors.relationship.message}</p>
              )}
            </div>

            <div style={{ marginTop: "10px" }}>
              <textarea
                className="description"
                placeholder="Story"
                {...register("story", { required: "Story is required" })}
              />
              {errors.story && <p className="error">{errors.story.message}</p>}
            </div>

            
            <div
              onClick={() => document.getElementById("file-upload").click()}
              className="input"
              style={{ marginTop: "10px", cursor: "pointer",display:"flex",justifyContent:"space-between" }}
            >
              <input
                id="file-upload"
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={(e) => validateFile(e.target.files[0])}
                className="input"
                style={{ display: "none" }}
              />
              {errors.photo && <p className="error">{errors.photo.message}</p>}
              <span>Upload Photo</span>{" "}
              {/* Optional text to indicate the action */}
              <img src={Image} alt=""/>
            </div>

            {upload && (
              <img
                src={upload}
                alt="Uploaded"
                style={{
                  marginBlock: "10px",
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                }}
              />
            )}
            <div style={{ marginTop: "10px" }}>
              <button type="submit" className="button" disabled={loading}>
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </BottomCards>
    </Main>
  );
};

export default MissingPersons;
