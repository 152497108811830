import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import { BottomCards, Main } from "./style";
import heart from "assets/images/svgs/home/heart.svg";
import Star from "assets/images/svgs/home/star.svg";
import Img from "../../../assets/images/svgs/home/image.svg";
import Locationpng from "../../../assets/images/svgs/home/location.png";
import Flag from "../../../assets/images/svgs/home/flag.svg";
import country from "../../../assets/images/svgs/home/country.png";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, firestore, storage } from "../../../firebase";
import { getAuth } from "firebase/auth";
import { CiHeart } from "react-icons/ci";
import { useForm } from "react-hook-form";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SearchContext } from "../../../context";
import { MdOutlineModeEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { CiCircleList } from "react-icons/ci";
import DeleteModal from "../../../components/Modals/delete";
import FilterModal from "../../../components/Modals/filter";
import Spinner from "../../../components/spinner/index";
import statesData from "../missing-persons/countries";
const CardData = [
  {
    title: "Loved Ones",
    img: heart,
    backgroundColor: "rgba(23, 131, 47, 1)",
    route: "/",
  },
  {
    title: "View Favorites",
    img: Star,
    backgroundColor: "rgba(233, 187, 69, 1)",
    route: "/favourites",
  },
  {
    title: "My List",
    img: <CiCircleList color="white" size={40} />,
    backgroundColor: "rgba(53, 142, 245, 1)",
    route: "/my-list",
  },
];

const Home = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [activeTab, setActiveTab] = useState("Loved Ones");
  // const [users, setUsers] = useState([]);
  const [visibleCount, setVisibleCount] = useState(20);
  const [loading, setLoading] = useState(true);
  // Search
  const {
    searchTerm,
    users,
    setUsers,
    filteredMissingPersons,
    setFilteredMissingPersons,
    combinedList,
    setCombinedList,
  } = useContext(SearchContext);

  const loadMoreUsers = () => {
    setVisibleCount((prevCount) => prevCount + 20); // Increase visible count by 20
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab.route);
    // Navigate to /auth/register if "Edit Profile" is clicked
    if (tab.title === "Edit Profile") {
      navigate("/auth/register"); // Use navigate instead of history.push
    } else if (tab.route === "/") {
      navigate("/");
      fetchUsers();
    } else if (tab.route === "/favourites") {
      navigate("/favourites");
      fetchUserFavorites();
    } else if (tab.route === "/my-list") {
      navigate("/my-list");
      MyList();
    }
  };

  const Profile = (id, fullName, photo, location, country, story, state) => {
    navigate("/profile", {
      state: { id, fullName, photo, location, country, story, state },
    });
  };

  const [currentRoute, setCurrentRoute] = useState();

  const location = useLocation();

  useEffect(() => {
    // Log the last segment of the URL (like 'my-list' from '/my-list')
    const currentRoute = location.pathname.split("/").pop();
    // console.log(`/${currentRoute}`);
    setCurrentRoute(currentRoute);
    setActiveTab(`/${currentRoute}`);
  }, [location]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      return navigate("/auth");
    }
  }, [navigate]);

  // Fetch users from Firestore
  const uid = localStorage.getItem("UID");

  // Generate state mapping from statesData
  const stateMapping = {};
  statesData.forEach((country) => {
    country.states.forEach((state) => {
      stateMapping[state.id] = state.name;
    });
  });

  const fetchUsers = async () => {
    const auth = getAuth();
    const currentUserId = auth.currentUser ? auth.currentUser.uid : uid;

    // console.log("Current User UID: ", currentUserId);

    const usersCollection = collection(db, "missing persons");
    const usersSnapshot = await getDocs(usersCollection);

    const usersList = usersSnapshot.docs.map((doc) => {
      const data = doc.data();
      // console.log("Fetched user data: ", data);

      let stateName;
      if (isNaN(data.state)) {
        stateName = data.state;
      } else {
        stateName = stateMapping[data.state] || "Unknown State";
      }

      // console.log("Final state for user: ", stateName); // Log the resolved state
      return { id: doc.id, ...data, state: stateName };
    });

    // console.log("Users list after mapping: ", usersList);
    setUsers(usersList);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // favrt

  const [favorites, setFavorites] = useState([]);

  const fetchUserFavorites = async () => {
    const currentUserId = localStorage.getItem("UID"); // Or use Firebase Auth
    if (!currentUserId) {
      toast.error("Session out Please Log in Again");
      return;
    }
    const userRef = doc(db, "users", currentUserId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      setFavorites(userData.favorites || []); // Set favorites array
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserFavorites();
  }, []);

  const toggleFavorite = async (userId) => {
    const currentUserId = localStorage.getItem("UID");
    if (!currentUserId) {
      toast.error("Session out Please Log in Again");
      return;
    }

    const userRef = doc(db, "users", currentUserId);
    const userSnapshot = await getDoc(userRef);

    let favoritesList = [];
    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      favoritesList = userData.favorites || [];
    } else {
      toast.error("Session out Please Log in Again");
      return;
    }

    let updatedFavorites;
    if (favoritesList.includes(userId)) {
      // Remove from list if it's already favorited
      updatedFavorites = favoritesList.filter((id) => id !== userId);
    } else {
      // Add to list if it's not yet favorited
      updatedFavorites = [...favoritesList, userId];
    }

    // Update Firestore for user's favorites
    await updateDoc(userRef, {
      favorites: updatedFavorites,
    });

    // Update the missing person's favorite status
    const missingPersonRef = doc(db, "missing persons", userId);
    await updateDoc(missingPersonRef, {
      favorite: !favoritesList.includes(userId),
    });

    setFavorites(updatedFavorites);
  };

  // #=================================== MY LIST ===================================#

  const myId = localStorage.getItem("UID");

  const MyList = () => {
    const unsubscribeUsers = onSnapshot(collection(db, "users"), (snapshot) => {
      const usersData = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        usersData.push({ id: doc.id, ...data });
      });

      setCombinedList((prevList) => {
        const existingIds = new Set(prevList.map((user) => user.id));
        const newUsers = usersData.filter((user) => !existingIds.has(user.id));
        return [...prevList, ...newUsers];
      });
    });

    const unsubscribeMissingPersons = onSnapshot(
      collection(db, "missing persons"),
      (snapshot) => {
        const missingPersonsData = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          // console.log("Missing person data: ", data); // Log entire data

          const stateId = data.state; // Ensure this matches your document structure
          // console.log(`State ID: ${stateId}`); // Log the stateId

          // Map stateId to state name
          const stateName = stateMapping[stateId] || "Unknown State"; // Use stateMapping for the state name

          // Add the state name to the missing person's data
          missingPersonsData.push({ id: doc.id, ...data, state: stateName });
        });

        // Existing filtering logic
        const createdByMe = missingPersonsData.filter(
          (person) => person.createdBy === myId
        );
        setFilteredMissingPersons(createdByMe);

        // Log matches found
        createdByMe.forEach((person) => {
          // console.log(
          //   `Match found: ${person.fullName} was created by me with state: ${person.state}`
          // );
        });
      }
    );
    return () => {
      unsubscribeUsers();
      unsubscribeMissingPersons();
    };
  };

  useEffect(() => {
    MyList();
  }, []);

  // #=================================== Delete ===================================#
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null); // Track the user to delete

  // Function to open the delete modal and set the user ID
  const openDeleteModal = (personId) => {
    setUserToDelete(personId); // Set the ID of the person to be deleted
    setDeleteModalOpen(true);
  };

  // Function to close the delete modal
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setUserToDelete(null);
  };

  // Function to delete the user
  const deleteMissingPerson = async (id) => {
    try {
      // Get a reference to the document you want to delete
      const personRef = doc(db, "missing persons", id);
      // Delete the document
      await deleteDoc(personRef);
      // console.log(`Deleted missing person with ID: ${id}`);
      // Remove the person from the state after deletion
      setFilteredMissingPersons((prevList) =>
        prevList.filter((person) => person.id !== id)
      );
    } catch (error) {
      console.error("Error deleting missing person: ", error);
    }
  };

  // const filteredUsers = users.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds).filter((user) =>
  //   user?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
  // );
  const filteredUsers = users
    .sort((a, b) => (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0))
    .filter(
      (user) =>
        user?.createdAt &&
        user?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
    );

  // console.log(filteredUsers);

  const FavrtFilter = users.filter((user) =>
    user?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // const filteredMissingPerson = filteredMissingPersons.filter((user) =>
  //   user?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
  // )
  // console.log(filteredMissingPerson);

  const filteredMissingPerson = filteredMissingPersons
    .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds) // Sort by createdAt, most recent first
    .filter((user) =>
      user?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  //  console.log(filteredMissingPerson);

  // edit

  const editMissing = (id) => {
    navigate(`/missing-persons/${id}`);
  };

  return (
    <Main>
      <ToastContainer />
      {/* // TABS */}

      <div className="backimg">
        <div className="container-fluid">
          <p className="main-heading">Dashboard</p>
          <div className="main-flex">
            {CardData.map((item, index) => (
              <div
                className="top-cards"
                key={index}
                onClick={() => handleTabClick(item)}
                style={{
                  cursor: "pointer",
                  // border: "2px solid #bdbdbd", // Highlight active tab

                  backgroundColor:
                    activeTab === item.route
                      ? item.route === "/"
                        ? "rgb(23, 131, 47)"
                        : item.route === "/favourites"
                        ? "rgb(233, 187, 69)"
                        : item.route === "/my-list"
                        ? "rgb(53, 142, 245)"
                        : "white"
                      : "white",
                  color: activeTab === item.route ? "white" : "rgba(65, 65, 65, 1)",
                }}
              >
                <div className="card-text">{item.title}</div>
                <div
                  className="card-img"
                  style={{ backgroundColor: item.backgroundColor }}
                >
                  {typeof item.img === "string" ? (
                    <img src={item.img} alt={item.title} />
                  ) : (
                    item.img // Render the icon directly
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* DIV 1 DATA to show on loved ones */}
      <BottomCards>
        <div className="container-fluid">
          <section>
            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <>
                {activeTab === "/" && (
                  <>
                    <div className="cards-container">
                      {filteredUsers.length > 0 ? (
                        filteredUsers
                          .slice(0, visibleCount)
                          .map((item, index) => {
                            const backgroundColor =
                              index % 2 === 0
                                ? "rgba(238, 251, 241, 1)"
                                : "rgba(255, 249, 234, 1)";

                            // let formattedDate;
                            // if (item.createdAt && item.createdAt.seconds) {
                            //   const createdAtDate = new Date(item.createdAt.seconds * 1000); // Convert seconds to milliseconds
                            //   formattedDate = createdAtDate.toLocaleString(); // Format to a readable string
                            // } else {
                            //   formattedDate = "Date not available"; // Fallback message
                            // }
                            return (
                              <div
                                className="card card-width border-0"
                                key={item.id} // Use item.id as the key for better uniqueness
                                style={{ backgroundColor }}
                              >
                                <div
                                  className="inner-flex"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    Profile(
                                      item.id,
                                      item.fullName,
                                      item.photo,
                                      item.location,
                                      item.country,
                                      item.story,
                                      item.state
                                    )
                                  }
                                >
                                  <div>
                                  <img
                                    src={item?.photo}
                                    alt="Img"
                                    className="profile-img"
                                  />
                                  </div>
                                  
                                  <div>
                                    <p className="cards-title">
                                      {item?.fullName}
                                    </p>
                                    <p className="m-0">
                                      <img
                                        src={Locationpng}
                                        alt="Location"
                                        style={{
                                          width: "22.39px",
                                          height: "22.39px",
                                          borderRadius: "40px",
                                        }}
                                      />
                                      <span className="cards-location ms-2">
                                        {item.location}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="main-heart-flex"
                                  // style={{
                                  //   display: "flex",
                                  //   alignItems: "center",
                                  //   gap: "10px",
                                  // }}
                                >
                                  

                                  <div>
                                  <img
                                    src={country}
                                    alt="Flag"
                                    style={{
                                      width: "22.39px",
                                      height: "22.39px",
                                      borderRadius: "40px",
                                    }}
                                  />  
                                  </div>
                                  
                                  <div className="heart-flex">
                                  <p className="cards-location">
                                    {item?.country} , {item.state} ,{" "}
                                    {item?.city}
                                  </p>
                                  <CiHeart
                                    size={30}
                                    color={
                                      Array.isArray(favorites) &&
                                      favorites.includes(item.id)
                                        ? "red"
                                        : "black"
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevents triggering parent click
                                      toggleFavorite(item.id); // Toggle favorite
                                    }}
                                    // style={{ cursor: "pointer",width: "30px",height:"30px" }}
                                  />
                                  </div>
                                  
                                    
                                  
                                </div>
                              </div>
                            );
                          })
                      ) : (
                        <p className="no-found">No loved ones found.</p> // Message when no loved ones are found
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      {filteredUsers.length > visibleCount && ( // Show Load More button if there are more users to load
                        <button
                          onClick={loadMoreUsers}
                          className="button"
                          style={{ marginTop: "10px", width: "25%" }}
                        >
                          Load More
                        </button>
                      )}
                    </div>
                  </>
                )}

                {activeTab === "/favourites" && (
                  <div className="cards-container">
                    {FavrtFilter.filter((user) => favorites?.includes(user.id))
                      .length > 0 ? (
                      FavrtFilter.filter((user) =>
                        favorites?.includes(user.id)
                      ).map((item, index) => {
                        const backgroundColor =
                          index % 2 === 0
                            ? "rgba(238, 251, 241, 1)"
                            : "rgba(255, 249, 234, 1)";

                        return (
                          <div
                            className="card card-width border-0"
                            key={item.id}
                            style={{ backgroundColor }}
                          >
                            <div
                              className="inner-flex"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                Profile(
                                  item.id,
                                  item.fullName,
                                  item.photo,
                                  item.location,
                                  item.country,
                                  item.story,
                                  item.state
                                )
                              }
                            >
                              <img
                                src={item?.photo}
                                alt="Img"
                                className="profile-img"
                              />
                              <div>
                                <p className="cards-title">{item?.fullName}</p>
                                <p className="m-0">
                                  <img
                                    src={Locationpng}
                                    alt="Location"
                                    style={{
                                      width: "22.39px",
                                      height: "22.39px",
                                      borderRadius: "40px",
                                    }}
                                  />
                                  <span className="cards-location ms-2">
                                    {item?.location}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="main-heart-flex">
                              <div>
                              <img
                                src={country}
                                alt="Flag"
                                style={{
                                  width: "22.39px",
                                  height: "22.39px",
                                  borderRadius: "40px",
                                }}
                              />
                              </div>
                             <div className="heart-flex">
                             <p className="cards-location">
                                {item?.country} , {item.state} , {item?.city}
                              </p>
                              <div
                                onClick={() => toggleFavorite(item.id)}
                                style={{ cursor: "pointer" }}
                              >
                                <CiHeart
                                  size={30}
                                  color={
                                    Array.isArray(favorites) &&
                                    favorites.includes(item.id)
                                      ? "red"
                                      : "black"
                                  }
                                />
                             </div>
                              
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="no-found">No favorites found.</p> // Message when no favorites are found
                    )}
                  </div>
                )}

                {activeTab === "/my-list" && (
                  <div className="cards-container">
                    {filteredMissingPerson.length > 0 ? (
                      filteredMissingPerson.map((item, index) => {
                        const backgroundColor =
                          index % 2 === 0
                            ? "rgba(238, 251, 241, 1)"
                            : "rgba(255, 249, 234, 1)";

                        return (
                          <>
                            <div
                              className="card card-width border-0"
                              key={item.id} // Use user ID as key for better performance
                              style={{ backgroundColor }}
                            >
                              <div
                                className="inner-flex"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  Profile(
                                    item.id,
                                    item.fullName,
                                    item.photo,
                                    item.location,
                                    item.country,
                                    item.story,
                                    item.state
                                  )
                                }
                              >
                                <img
                                  src={item?.photo}
                                  alt="Img"
                                  className="profile-img"
                                />
                                <div>
                                  <p className="cards-title">
                                    {item?.fullName}
                                  </p>
                                  <p className="m-0">
                                    <img
                                      src={Locationpng}
                                      alt="Location"
                                      style={{
                                        width: "22.39px",
                                        height: "22.39px",
                                        borderRadius: "40px",
                                      }}
                                    />
                                    <span className="cards-location ms-2">
                                      {item?.location}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="main-heart-flex"
                              >
                                <div>
                                <img
                                  src={country}
                                  alt="Flag"
                                  style={{
                                    width: "22.39px",
                                    height: "22.39px",
                                    borderRadius: "40px",
                                  }}
                                />
                                </div>
                                <div className="heart-flex">
                                <p className="cards-location">
                                  {item?.country} , {item.state} , {item?.city}
                                </p>
                                <div className="flex-edit-delete">
                                <div
                                  className="backgroud"
                                  onClick={() => editMissing(item?.id)}
                                >
                                  <MdOutlineModeEdit
                                    size={20}
                                    color=" #17832f"
                                  />
                                </div>
                                <div
                                  className="delete"
                                  onClick={() => openDeleteModal(item?.id)}
                                >
                                  <MdDeleteOutline size={20} color="white" />
                                </div>
                              </div>
                                </div>
                                
                                
                              </div>
                              
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <p className="no-found">
                        No missing persons created by you.
                      </p>
                    )}
                  </div>
                )}
              </>
            )}
          </section>
        </div>

        <DeleteModal
          open={isDeleteModalOpen}
          handleClose={closeDeleteModal}
          handleDelete={() => {
            if (userToDelete) {
              deleteMissingPerson(userToDelete); // Call the deletion function
              closeDeleteModal(); // Close the modal after deletion
            }
          }}
        />
      </BottomCards>
    </Main>
  );
};

export default Home;
