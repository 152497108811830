import React from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { Delete } from './style';

const DeleteModal = ({ open, handleClose, handleDelete }) => {
  if (!open) return null;

  return (
    <Delete>
      <div className="modal-overlay">
        <div className="modal-container">
          <div className="modal-header">
            <p className='heading'>Confirm Delete</p>
            <IoCloseSharp className="close-icon" onClick={handleClose} />
          </div>
          <div className="modal-body">
            <p className='sub-heading'>Are you sure you want to delete this Missing Person?</p>
          </div>
          <div className="modal-footer">
            <button className="confirm-btn" onClick={handleDelete}>Yes</button>
            <button className="cancel-btn" onClick={handleClose}>No</button>
          </div>
        </div>
      </div>
    </Delete>
  );
};

export default DeleteModal;
