import styled  from "styled-components";

export const Profile = styled.div`
width: 100%;
/* background-color: aquamarine; */
`;

export const Register = styled.div`
  width: 100%;
  height: 100vh;  
  display: flex;
  justify-content: center;
  align-items: center;  

  @media (max-width: 768px) {
    
    height: auto;
     
  }
  
  form {
    width: 100%;
    max-width: 600px;

    padding: 20px;
    box-sizing: border-box; 
  }
  .form-padding{
    padding: "0px 129px"
    
  }

  .welcome-text {
    font-family: 'Baloo Tamma 2';
    font-weight: 600;
    font-size: 40px;
    color: black; 
    margin: 0px;
  }
  .welcome-text2 {
    font-family: 'Baloo Tamma 2';
    font-weight: 500;
    font-size: 16px;
    color: 16px;
    margin-bottom: 35px;
  }

  .label {
    font-family: 'Baloo Tamma 2';
    font-weight: 600;
    font-size: 18px;
    color: #414141; 
  }

  .input-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px; */
@media (max-width:768px) {
  flex-direction: column;
}
}


.input {
  width: 100%;
  padding-left: 40px;  
  border: 0px;
  outline: none;

  font-family: "Baloo Tamma 2";
  height: 53.59px;
  border-radius: 10px;
  padding: 0px 15px;
  margin-bottom: 10px;
  box-sizing: border-box;

  background-color: #f3f3f3;
  font-weight: 600;
    font-size: 18px;
    padding-top: 5px;
    color: rgba(65, 65, 65, 1);
    @media (max-width: 768px) {
      font-size: 14px;
    }
}
.input-upload {
  width: 100%;
  padding-left: 40px;  
  border: 0px;
  outline: none;
  font-family: 'Baloo Tamma 2';
  color: #B8B8B8;
  border-radius: 10px;
  padding: 0px 15px;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  background-color: #f3f3f3;
}

.location-flex{
    width: 100%;
    display: flex;
    gap: 10px;
}
.input-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px; */
  gap: 10px;
}
.input-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-70%);
    width: 30px;
    height: 30px;
    pointer-events: none;
}



  .button {
    background: linear-gradient(
    90deg,
    rgba(45, 201, 145, 1) 0%,
    rgba(23, 131, 47, 1) 100%
  );
    color: white;
    width: 100%;
    height: 38px;
    border-radius: 10px;
    border: 0;
    font-family: 'Baloo Tamma 2';
    font-weight: 600;
    font-size: 19px;
    outline: none;
    cursor: pointer;
    height: 53.59px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 7px;
  }
  .button:hover {
    background: linear-gradient(90deg, rgba(23, 131, 47, 1) 0%, rgba(45, 201, 145, 1) 100%);
    transition: all 0.4s;
  }
  .error{
  font-family: 'Baloo Tamma 2';
    font-weight: 500;
    font-size: 16px;
    color: red;
}

.input-file {
  display: none; /* Hide the file input */
}

.input-icon {
  cursor: pointer; /* Change cursor to indicate clickable */
}
.upload{
  width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    @media (max-width:768px) {
      margin-bottom: 20px;
    }
}


 
`;
