// SearchContext.js
import React, { createContext, useState } from "react";

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredMissingPersons, setFilteredMissingPersons] = useState([]);
  const [combinedList, setCombinedList] = useState([]);
  

  return (
    <SearchContext.Provider value={{ searchTerm, setSearchTerm , filtered, setFiltered,users, setUsers,filteredMissingPersons, setFilteredMissingPersons,combinedList, setCombinedList}}>
      {children}
    </SearchContext.Provider>
  );
};
