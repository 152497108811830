//  eslint-disable prettier/prettier
import Header from "components/header";
import Dashboard from "../../../containers/dashboard/index";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../footer";
const MainLayout = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     // If no token is found, redirect to the login page
  //     navigate("/auth");
  //   } 
  // }, []);
  const location = useLocation();
  const isBlankPage =
  location.pathname === "/verify"  // Include the verify page

 

  return (
    
    // <div>
    //   {!isBlankPage && (
    //   <Header/>
    // )}
    //   <Dashboard/>
    //  <div>
    //  <Footer/>

    //  </div>
    // </div>
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    {!isBlankPage && <Header />}
    <div style={{ flex: 1 }}> {/* This makes the Dashboard take the available space */}
      <Dashboard />
    </div>
    <Footer />
  </div>
  );
};

export default MainLayout;