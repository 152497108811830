// Spinner.js
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Spinner = ({ loading, size = 35, color = '#FF8F50' }) => {
  return (
    <div className="spinner-container" style={styles.container}>
      <ClipLoader loading={loading} size={size} color={color} />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20vh', // Full height for centering
  },
};

export default Spinner;
