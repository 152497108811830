import React from 'react' 
import { Main } from './style'

const Footer = () => {
  return (
    <Main>
         <p className="line"></p>
        <div className="text-center">
          <p className="milap-text">Milap @2024. All rights reserved.</p>
        </div>
    </Main>
  )
}

export default Footer