import styled from "styled-components";

export const Delete = styled.div`
  /* Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Container */
.modal-container {
  background: #fff;
  width: 550px;
  max-width: 90%;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

@media (max-width: 425px) {
  .modal-container {
    width: 90%;
    padding: 15px;
  }
}

/* Modal Header */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.modal-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

/* Close Icon */
.close-icon {
  font-size: 30px;
  cursor: pointer;
}

/* Modal Body */
.modal-body {
  padding: 20px 0;
  font-size: 18px;
}

/* Modal Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.confirm-btn,
.cancel-btn {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.confirm-btn {
  background: #ff4d4d; /* Red */
  color: white;
}

.cancel-btn {
  background: #ccc; /* Gray */
  color: black;
}
.heading{
  font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 30px; 
    margin: 0;
}
.sub-heading{
  font-family: "Baloo Tamma 2";
    font-weight: 500;
    font-size: 24px; 
}

`;
