import styled from "styled-components";

export const Forget = styled.div`
  width: 100%;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1; /* Background color for the entire screen */
  padding: 20px 0;

  form {
    width: 100%;
    max-width: 643px;
    padding: 20px;
    box-sizing: border-box; /* Ensure padding is included in width */
  }
  .form-padding {
    padding: "0px 129px";
  }

  .welcome-text {
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 40px;
    color: black;
    margin-bottom: 10px;
  }
  .welcome-text2 {
    font-family: "Baloo Tamma 2";
    font-weight: 500;
    font-size: 16px;
    color: 16px;
    margin-bottom: 35px;
  }

  .label {
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 18px;
    color: #414141;
    margin-bottom: 10px;
  }

  .input-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input {
    width: 100%;
    padding-left: 40px; /* Add padding to make space for the icon */
    border: 0px;
    outline: none;
    font-family: "Baloo Tamma 2";
    color: black;
    height: 53.59px;
    border-radius: 10px;
    padding: 0px 20px;
    /* margin-bottom: 10px; */
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    background-color: #ffffff;
    padding-top: 7px;
  }
  .input-password {
    width: 100%;
    padding-left: 40px; /* Add padding to make space for the icon */
    border: 0px;
    outline: none;
    font-family: "Baloo Tamma 2";
    color: black;
    height: 53.59px;
    border-radius: 10px;
    padding: 0px 40px;
    /* margin-bottom: 10px; */
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    background-color: #ffffff;
  }

  .input-icon {
    position: absolute;
    left: 10px; /* Position the icon inside the input field */
    top: 50%; /* Center vertically */
    transform: translateY(-93%);
    width: 20px;
    height: 20px; /* Adjust icon size as needed */
    pointer-events: none; /* Ensure the icon doesn't block input clicks */
  }

  .button {
    background: linear-gradient(
      90deg,
      rgba(45, 201, 145, 1) 0%,
      rgba(23, 131, 47, 1) 100%
    );
    color: white;
    width: 100%;
    height: 38px;
    border-radius: 10px;
    border: 0;
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 19px;
    outline: none;
    cursor: pointer;
    height: 53.59px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button:hover {
    background: linear-gradient(
      90deg,
      rgba(23, 131, 47, 1) 0%,
      rgba(45, 201, 145, 1) 100%
    );
    transition: all 0.4s;
  }
  .already-button {
    background-color: #e9bb45;
    color: white;
    width: 100%;
    height: 38px;
    border-radius: 10px;
    border: 0;
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 19px;
    outline: none;
    cursor: pointer;
    height: 53.59px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .already-button:hover {
    color: #e9bb45;
    border: 1px solid rgba(23, 131, 47, 1);
    background-color: #ffffff;
    transition: all 0.4s;
  }
  .error-message {
    font-family: "Baloo Tamma 2";
    font-weight: 500;
    font-size: 16px;
    color: red;
  }
  .register {
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 19px;
    outline: none;
    cursor: pointer;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    display: none;
  }
  @media (max-width: 991px) {
    .logo {
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
    }
    .welcome-text{
    font-size: 30px;
  }
}
`;
