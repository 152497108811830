import {  Route, Routes } from "react-router-dom";
import Home from "./home";
import Profile from "./Profile";
import UserProfile from "./my-profile";
import MissingPersons from "./missing-persons";

const Dashboard = ( ) => {
    return (
 
            <Routes>
                <Route path="/" Component={Home } />
                <Route path="/favourites" Component={Home } />
                <Route path="/my-list" Component={Home } />
                <Route path="/profile" Component={Profile} />
                <Route path="/my-profile" Component={UserProfile} />
                        <Route path="/missing-persons" Component={MissingPersons} />
                        <Route path="/missing-persons/:id" Component={MissingPersons} />
                        <Route path="/verify-email" Component={MissingPersons} />
            
            </Routes>
   
    )
}

export default Dashboard;