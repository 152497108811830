import React, { useEffect, useState, useContext } from "react";
import Logo from "../../../src/assets/images/svgs/header/Logo.svg";
import Arrow from "../../../src/assets/images/svgs/header/Polygon 5.svg";
import { Main } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchContext } from "../../context/index"; // Adjust path if necessary
import Profile from "../../../src/assets/images/svgs/header/profile.svg";
import Filter from "../../../src/assets/images/svgs/header/filter.svg";
import { FaSearch } from "react-icons/fa";
import FilterModal from "../Modals/filter";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import statesData from "../../containers/dashboard/missing-persons/countries";
import MyList from "../generics";
import { getAuth } from "firebase/auth";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false); // State to handle mobile menu
  const [userData, setUserData] = useState([]);
  const [headerVisible, setHeaderVisible] = useState(true);
  // const location = useLocation();
  
  useEffect(() => {
    const auth = getAuth();
    const user = localStorage.getItem("UID");
    console.log(user); // Log the user ID from localStorage

    if (user) {
      // Fetch user data from Firestore
      const fetchUserData = async () => {
        try {
          const userDocRef = doc(db, 'users', user); // Use the user ID to reference Firestore
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            setUserData(userDoc.data()); // Set user data 
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        } 
      };

      fetchUserData();
    } else {
      console.log("No user is signed in.");
      // Set loading to false if no user is signed in
    }
  }, [userData]);

  const collections = ["missing persons", "users"];
  const { searchTerm, setSearchTerm, users, setUsers ,filteredMissingPersons, setFilteredMissingPersons,combinedList, setCombinedList} =
    useContext(SearchContext);

  const username = localStorage.getItem("username");
  const photo = localStorage.getItem("userPhoto");
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("UID");
    localStorage.removeItem("userPhoto");
    localStorage.removeItem("username");
    navigate("/auth/");
  };

  const Myprofile = (state) => {
    navigate("/my-profile", { state });
  };

  const AddMissing = (state) => {
    navigate("/missing-persons", { state });
  };
  const Home = (state) => {
    navigate("/", { state });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    navigate("/")
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
 // Close menu on route change for screens smaller than 768px
//  useEffect(() => {
//   if (window.innerWidth <= 768) {
//     setMenuOpen(false);
//   }
// }, [location.pathname]);
  useEffect(() => {
    const filtered = collections.filter((item) =>
      item.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
    console.log(filteredData);
  }, [searchTerm]);

  //filter
  const { fetchUsers } = MyList();

  const stateMapping = {};
  statesData.forEach((country) => {
    country.states.forEach((state) => {
      stateMapping[state.id] = state.name;
    });
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentRoute, setCurrentRoute] = useState();
  const handleOpenModal = () => {
    setIsModalOpen(true);
    setMenuOpen(false)
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFilter = async (countryFilter, stateIdFilter, cityFilter) => {
    try {
      
      const country = countryFilter; // Example: "Pakistan"
      const stateId = stateIdFilter; // Example: "3176"
      const city = cityFilter; // Example: "85674"

      console.log("✨ Filters applied:", country, stateId, city);

      const filters = [];

      if (country) {
        filters.push(where("country", "==", country));
        console.log(`🗺️ Country filter added: ${country}`);
      }
      if (stateId) {
        filters.push(where("state", "==", stateId));
        console.log(`🏞️ State filter added: ${stateId}`);
      }
      if (city) {
        filters.push(where("city", "==", city));
        console.log(`🌆 City filter added: ${city}`);
      }

      console.log("🔍 Constructed Filters:", filters);

      const q = query(collection(db, "missing persons"), ...filters);
      const querySnapshot = await getDocs(q);
      console.log(`📜 Number of documents matched: ${querySnapshot.size}`);

      const filteredData = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        let stateName;
        if (isNaN(data.state)) {
          stateName = data.state;
        } else {
          stateName = stateMapping[data.state] || "Unknown State";
        }

        filteredData.push({ id: doc.id, ...doc.data(), state: stateName });
      });

      console.log("🎉 Filtered Data:", filteredData);
      setUsers(filteredData);
      setFilteredMissingPersons(filteredData);
    } catch (error) {
      console.error("🚨 Error fetching filtered data: ", error);
    }
  };
  const handleReset = async () => {
    if (currentRoute === "") {
      fetchUsers();
    } else if (currentRoute === "favourites") {
      fetchUsers();
    } else if (currentRoute === "my-list") {
      MyList();
    }
  };
  
  return (
    <Main>
      <div className="container-fluid main-container">
        <div>
          <img src={Logo} alt="Logo" className="logo" onClick={() => Home()} />
        </div>
        <div
          className={`burger ${menuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`main-flex ${menuOpen ? "menu-open" : ""}`}>
          <div className="background" onClick={() => AddMissing()}>
            Add Missing Persons
          </div>
          <div className="filter-bg" onClick={handleOpenModal}>
            <img src={Filter} alt="" style={{ width: "100%" }} />
          </div>
          <div className="input-flex">
            <input
              type="search"
              placeholder="Search by Name "
              value={searchTerm}
              onChange={handleInputChange}
              className="input"
            />

            <FaSearch />
          </div>

          <div className="main-flex2">
            <img src={userData?.photo} alt="image" className="main-photo" />
            <div
              className="dropdown-container"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <div className="dropdown-selected">
                <p className="name">
                  {userData?.firstName} {userData?.lastName}
                  <span style={{ marginLeft: "30px" }}>
                    <img src={Arrow} alt="Arrow" />
                  </span>
                </p>
              </div>
              {dropdownOpen && (
                <div className="dropdown-options">
                  <div className="dropdown-option" onClick={() => Myprofile()}>
                    <img src={Profile} alt="Profile" className="dropdown-img" />
                    <span>Profile</span>
                  </div>
                  <div className="dropdown-option">
                    <button className="log-out-btn" onClick={handleLogout}>
                      LOG OUT
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
         
        </div>
      </div>
      <FilterModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        handleFilter={handleFilter}
      />
    </Main>
  );
};

export default Header;
