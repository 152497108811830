import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { useState, useEffect, useContext } from "react";
import { db } from "../../firebase";
import statesData from "../../containers/dashboard/missing-persons/countries";
import { getAuth } from "firebase/auth";
import { SearchContext } from "context";

const useCombinedList = (setCombinedList) => {
  useEffect(() => {
    const unsubscribeUsers = onSnapshot(collection(db, "users"), (snapshot) => {
      const usersData = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        usersData.push({ id: doc.id, ...data });
      });

      setCombinedList((prevList) => {
        const existingIds = new Set(prevList.map((user) => user.id));
        const newUsers = usersData.filter((user) => !existingIds.has(user.id));
        return [...prevList, ...newUsers];
      });
    });

    return () => unsubscribeUsers();
  }, [ ]);
};

const useFilteredMissingPersons = (myId, stateMapping, setFilteredMissingPersons) => {
  useEffect(() => {
    const unsubscribeMissingPersons = onSnapshot(
      collection(db, "missing persons"),
      (snapshot) => {
        const missingPersonsData = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          // console.log("Missing person data: ", data);

          const stateId = data.state;
          // console.log(`State ID: ${stateId}`);

          const stateName = stateMapping[stateId] || "Unknown State";
          missingPersonsData.push({ id: doc.id, ...data, state: stateName });
        });

        const createdByMe = missingPersonsData.filter(
          (person) => person.createdBy === myId
        );
        setFilteredMissingPersons(createdByMe);

        createdByMe.forEach((person) => {
          // console.log(
          //   `Match found: ${person.fullName} was created by me with state: ${person.state}`
          // );
        });
      }
    );

    return () => unsubscribeMissingPersons();
  }, [ ]);
};

const MyList = () => {


  const {  users, setUsers,filteredMissingPersons, setFilteredMissingPersons,combinedList, setCombinedList } =
  useContext(SearchContext);
 
  
  const [loading, setLoading] = useState(true);
  const myId = localStorage.getItem("UID");

  const stateMapping = {};
  statesData.forEach((country) => {
    country.states.forEach((state) => {
      stateMapping[state.id] = state.name;
    });
  });

  useCombinedList(setCombinedList);
  useFilteredMissingPersons(myId, stateMapping, setFilteredMissingPersons);

  const fetchUsers = async () => {
    const uid = localStorage.getItem("UID");
    const auth = getAuth();
    const currentUserId = auth.currentUser ? auth.currentUser.uid : uid;

    // console.log("Current User UID: ", currentUserId);

    const usersCollection = collection(db, "missing persons");
    const usersSnapshot = await getDocs(usersCollection);

    const usersList = usersSnapshot.docs.map((doc) => {
      const data = doc.data();
      // console.log("Fetched user data: ", data);

      let stateName;
      if (isNaN(data.state)) {
        stateName = data.state;
      } else {
        stateName = stateMapping[data.state] || "Unknown State";
      }

      // console.log("Final state for user: ", stateName);
      return { id: doc.id, ...data, state: stateName };
    });

    // console.log("Users list after mapping: ", usersList);
    setUsers(usersList);
    setLoading(false);
  };


  const AllMyList = () => {
    const unsubscribeUsers = onSnapshot(collection(db, "users"), (snapshot) => {
      const usersData = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        usersData.push({ id: doc.id, ...data });
      });

      setCombinedList((prevList) => {
        const existingIds = new Set(prevList.map((user) => user.id));
        const newUsers = usersData.filter((user) => !existingIds.has(user.id));
        return [...prevList, ...newUsers];
      });
    });

    const unsubscribeMissingPersons = onSnapshot(
      collection(db, "missing persons"),
      (snapshot) => {
        const missingPersonsData = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          // console.log("Missing person data: ", data); // Log entire data

          const stateId = data.state; // Ensure this matches your document structure
          // console.log(`State ID: ${stateId}`); // Log the stateId

          // Map stateId to state name
          const stateName = stateMapping[stateId] || "Unknown State"; // Use stateMapping for the state name

          // Add the state name to the missing person's data
          missingPersonsData.push({ id: doc.id, ...data, state: stateName });
        });

        // Existing filtering logic
        const createdByMe = missingPersonsData.filter(
          (person) => person.createdBy === myId
        );
        setFilteredMissingPersons(createdByMe);

        // Log matches found
        createdByMe.forEach((person) => {
          // console.log(
          //   `Match found: ${person.fullName} was created by me with state: ${person.state}`
          // );
        });
      }
    );
    return () => {
      unsubscribeUsers();
      unsubscribeMissingPersons();
    };
  };

  return {
    combinedList,
    filteredMissingPersons,
    users,
    loading,
    fetchUsers,
    AllMyList
  };
};

export default MyList;
