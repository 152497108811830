import styled from "styled-components";
import BackImg from '../../../assets/images/svgs/home/mask-group.png'


export const Main = styled.div`
width: 100%;

.backimg{
    height: 100vh; 
   /* Fallback background color */
  background-image: url(${BackImg});
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  width: 100%;
  height: 300px;
  /* display: flex;
  justify-content: center;
  align-items: center; */

}
.img{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 179px;
    border-radius: 30px;
}

.name{
    text-align: center;
    margin: 0;
    margin-top: 10px;
    font-size: 29px;
    font-family:"Baloo Tamma 2";
    font-weight: 600;
    color: rgba(65, 65, 65, 1);
}
.cards-location{
   font-family: "Lato";
   font-weight: 400;
   font-size: 14px;
   color: rgba(113, 134, 117, 1);
   margin: 0px;
}
.flex{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

}
.icon{
    width: 16px;
    height: 16px;
}
.story{
    font-size: 26px;
    font-family:"Baloo Tamma 2";
    font-weight: 600;
    color: rgba(65, 65, 65, 1);
}
.pading{
    padding: 0px 390px;
}
.card-border{
    border: 1px solid rgba(222, 222, 222, 1);
}
.card-padding{
    margin: 0;
    padding: 40px 40px;
}

@media (max-width:1300px) {
    .pading{
        padding: 0px;
    }
}
.main-img{
    width: 162.19px;
    border-radius: 96px;
    height: 160px;
}

`