
import { Routes, Route, useLocation } from 'react-router-dom';
import Login from './login';
import Resgister from './register';
import BlankPage from 'components/blank-page'; 
import { useEffect } from 'react';
import ForgetPassword from './forget-password';

const AuthRoutes = () => {
    const location = useLocation();

    useEffect(() => {
        // Prevent navigation away from '/verify' route
        if (location.pathname === '/verify') {
            // Optionally, you can use a listener to prevent back navigation
            const handlePopState = (event) => {
                window.history.pushState(null, '', location.pathname);
            };
            window.history.pushState(null, '', location.pathname);
            window.addEventListener('popstate', handlePopState);

            return () => {
                window.removeEventListener('popstate', handlePopState);
            };
        }
    }, [location.pathname]);
    return (
        <>
        <Routes>
            
            <Route path='/' Component={Login} />
            <Route path='/register' Component={Resgister} />
            <Route path='/verify' Component={BlankPage} />
            <Route path='/forget-password' Component={ForgetPassword} />
           
        </Routes>
        </>
    )
}

export default AuthRoutes;