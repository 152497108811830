import styled from "styled-components";
import BackImg from "../../../assets/images/svgs/home/mask-group.png";

export const Main = styled.div`
  width: 100%;

  .backimg {
    height: 100vh;
    /* Fallback background color */
    background-image: url(${BackImg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    padding-bottom: 0px;
    /* display: flex;
  justify-content: center;
  align-items: center; */
  }
  .main-heading {
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 40px;
    color: rgba(65, 65, 65, 1);
    margin: 0;
    padding-block: 39px;
  }
  .top-cards {
    width: 100%;
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 145.05px;
  }
  .card-img {
    width: 75px;
    padding: 12px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-text {
    font-family: "Baloo Tamma 2";
    font-weight: 500;
    font-size: 30px;
    /* color: rgba(65, 65, 65, 1); */
    margin: 0px;
  }
  .main-flex {
    display: flex;
    justify-content: start;
    gap: 20px;
  }
  .button {
    background: linear-gradient(
      90deg,
      rgba(45, 201, 145, 1) 0%,
      rgba(23, 131, 47, 1) 100%
    );
    color: white;
    width: 15%;
    height: 38px;
    border-radius: 10px;
    border: 0;
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 19px;
    outline: none;
    cursor: pointer;
    height: 53.59px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .reset {
    background: rgb(234, 234, 234);
    color: rgb(109, 109, 109);
    width: 15%;
    height: 38px;
    border-radius: 10px;
    border: 0;
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 19px;
    outline: none;
    cursor: pointer;
    height: 53.59px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 814px) {
    .main-flex {
      flex-wrap: wrap;
    }
    .backimg {
      height: auto;
      padding-bottom: 20px;
    }
  }
`;
export const BottomCards = styled.div`
  width: 100%;
  margin-top: 20px;
  .card-width {
    width: 100%;
    height: 114.46px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0px 40px;
  }
  .profile-img {
    width: 55.22px;
    height: 55.22px;
    border-radius: 30px;
  }
  .cards-title {
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 22px;
    color: rgba(65, 65, 65, 1);
    margin: 0px;
  }
  .cards-location {
    font-family: "Lato";
    font-weight: 400;
    font-size: 14px;
    color: rgba(113, 134, 117, 1);
    margin: 0px;
    width: 100%;
  }
  .inner-flex {
    display: flex;
    gap: 10px;
  }
  .cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two cards per row */
    gap: 20px; /* Space between the cards */
  }
  @media (max-width: 1477px) {
    .cards-container {
      grid-template-columns: 1fr; /* One card per row on smaller screens */
    }
  }

  .line {
    border-bottom: 1px solid rgba(216, 216, 216, 1);
    margin-top: 70px;
  }
  .milap-text {
    font-family: "Lato";
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
    margin: 0px;
    margin-bottom: 20px;
  }
  @media (max-width: 747px) {
    .card-width {
      flex-direction: column;
      justify-content: center;
      align-items: start;
      padding: 20px 20px;
      height: auto;
    }
  }

  .edit-text {
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 28px;
    color: rgba(65, 65, 65, 1);
    margin: 0px;
    margin-bottom: 20px;
  }
  .input {
    width: 100%;
    background-color: rgba(248, 248, 248);
    border: 0px;
    height: 48px;
    border-radius: 10px;
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 18px;
    color: rgba(65, 65, 65, 1);
    padding: 12px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .description {
    width: 100%;
    background-color: rgba(248, 248, 248);
    border: 0px;
    height: 99.4px;
    border-radius: 10px;
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 18px;
    color: rgba(65, 65, 65, 1);
    padding: 12px;
    resize: none;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .input-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
  }
  .input-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    pointer-events: none;
    background: rgba(234, 234, 234, 1);
    width: 79px;
    padding: 4px;
    border-radius: 6px;
  }
  .button {
    background: linear-gradient(
      90deg,
      rgba(45, 201, 145, 1) 0%,
      rgba(23, 131, 47, 1) 100%
    );
    color: white;
    width: 100%;
    height: 38px;
    border-radius: 10px;
    border: 0;
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 19px;
    outline: none;
    cursor: pointer;
    height: 53.59px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 7px;
  }
  .button:hover {
    background: linear-gradient(90deg, rgba(23, 131, 47, 1) 0%, rgba(45, 201, 145, 1) 100%);
    transition: all 0.4s;
  }
  .error {
    font-family: "Baloo Tamma 2";
    font-weight: 500;
    font-size: 16px;
    color: red;
    margin-bottom: 35px;
  }
  .form {
    padding: 20px;
    width: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 26px;
  }
  @media (max-width:620px) {
    .form{
      width: 100%;
    }
  }
  .flex-edit-delete{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .backgroud{
    background-color: #E9BB45;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;

  }
  .delete{
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;

  }
  .no-found{
    font-family: "Baloo Tamma 2";
    font-weight: 500;
    font-size: 24px;
    color: red;
    margin-bottom: 35px;
  }
  .heart-flex{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .main-heart-flex{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  @media (max-width:747px) {
    .heart-flex{
      width: 100%;
      justify-content: space-between;
    }
    .main-heart-flex{
      width: 100%;
     
    }
  }

  /// missing persons
  .missing-persons{
    display: flex;
             justify-content: center;
             align-items: center;
             height: 90vh;
             flex-direction: column
  }
  @media (max-width:611px) {
    .missing-persons{
    padding: 10px;
    height: auto;
  }
  .edit-text{
    margin-bottom: 0;
  }
}
`;
