import React, { useState } from "react";
import { Register } from "./style";
import Image from "../../../assets/images/svgs/register/OBJECTS.svg";
import { auth, db, storage } from "../../../firebase"; // Ensure you import your Firebase auth, Firestore instance, and Storage instance
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import necessary functions for storage
import { useNavigate } from "react-router-dom";
import { sendEmailVerification } from "firebase/auth";
import statesData from "../../dashboard/missing-persons/countries";
import { GoEye, GoEyeClosed } from "react-icons/go";
import Logo from "../../../assets/images/svgs/logo/Logo.svg";

const Resgister = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    watch,
    trigger,
    setValue,
  } = useForm(); // Initialize useForm

  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(null); // For photo upload
  const [upload, setUpload] = useState(null); // For photo upload
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true); // Start loading

    try {
      // Using createUserWithEmailAndPassword for signup
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      const user = userCredential.user;
      // const actionCodeSettings = {
      //   // url: 'http://localhost:3001/auth/', // Replace with your actual URL
      //   url: 'https://app.millap.pk/auth/', // Replace with your actual URL
      //   handleCodeInApp: true, // This should be true if you're using the app to handle the verification
      // };

      await sendEmailVerification(user);
      // Send verification email
      // await sendEmailVerification(user);
      console.log("Verification email sent to:", user.email);

      // Store the token in localStorage
      // localStorage.setItem("token", user.accessToken);
      // console.log("Token set in localStorage:", user.accessToken);

      let photoURL = "";

      // Upload the photo to Firebase Storage if it exists
      if (photo) {
        const storageRef = ref(storage, `users/${user.uid}/profile.jpg`);
        await uploadBytes(storageRef, photo);
        photoURL = await getDownloadURL(storageRef);
        console.log("Photo uploaded to storage:", photoURL);
      }

      // Prepare user data
      const userData = {
        email: user.email,
        firstName: data.firstName,
        lastName: data.lastName,
        country: data.country,
        city: data.city,
        location: data.location,
        photo: photoURL,
        createdAt: new Date().toISOString(),
        password: data.password,
        uid: user.uid,
      };

      await setDoc(doc(db, "users", user.uid), userData);

      // Notify the user about the successful signup
      toast.success(`Welcome, ${user.email}! Your account has been created.`, {
        position: "top-right",
        autoClose: 3000,
      });
      navigate("/auth/verify");
    } catch (error) {
      console.error("Error signing up:", error);

      // Check Firebase error codes and show specific toastr messages
      switch (error.code) {
        case "auth/email-already-in-use":
          toast.error(
            "This email is already registered. Please try logging in or use a different email.",
            {
              position: "top-right",
              autoClose: 3000,
            }
          );
          break;
        case "auth/invalid-email":
          toast.error("Invalid email address. Please check and try again.", {
            position: "top-right",
            autoClose: 3000,
          });
          break;
        case "auth/weak-password":
          toast.error(
            "Password is too weak. Please choose a stronger password.",
            {
              position: "top-right",
              autoClose: 3000,
            }
          );
          break;
        default:
          toast.error("Signup failed. Please try again.", {
            position: "top-right",
            autoClose: 3000,
          });
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Function to validate file type
  const validateFile = (file) => {
    // debugger;
    const validTypes = ["image/jpeg", "image/png"]; // Define valid types
    if (file) {
      if (!validTypes.includes(file.type)) {
        setError("photo", {
          type: "manual",
          message: "Only .png, .jpg, or .jpeg files are accepted.",
        });
        setPhoto(null); // Reset photo state if invalid
      } else {
        const filePath = URL.createObjectURL(file);
        console.log(filePath, "sds");

        clearErrors("photo"); // Clear error if valid
        setUpload(filePath);
        setPhoto(file); // Set the valid photo
      }
    }
  };
  // cites and country

  const [selectedCountry, setSelectedCountry] = useState("");
  const [availableCities, setAvailableCities] = useState([]);

  // Function to handle the country selection
  const handleCountryChange = (e) => {
    const selected = e.target.value;
    setSelectedCountry(selected);

    // Reset city selection whenever country changes
    setValue("city", "");
    const countryData = statesData.find((country) => country.name === selected);

    // If a country is found, set available cities, otherwise clear cities
    if (countryData) {
      const cities = countryData.states.flatMap((state) =>
        state.cities.map((city) => city.name)
      );
      setAvailableCities(cities);
    } else {
      setAvailableCities([]);
    }
  };

  const Login = (state) => {
    navigate("/auth", { state });
  };

  //password show
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div>
      <ToastContainer />
      <Register>
        <form onSubmit={handleSubmit(onSubmit)} className="form-padding">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>
          <p className="welcome-text">Enter Your Details</p>
          <p className="welcome-text2">
            Please Enter Your Details To Create An Account
          </p>

          <label className="label">Email <span className="staric">*</span></label>
          <div className="input-container">
            <input
              type="email"
              className="input"
              placeholder="Email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address",
                },
              })}
            />
          </div>
          {errors.email && <p className="error">{errors.email.message}</p>}

          <label className="label">Password <span className="staric">*</span></label>
          <div className="input-container" style={{ position: "relative" }}>
            <input
              type={showPassword ? "text" : "password"} // Toggle between text and password
              className="input"
              placeholder="Password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: "10px",
                top: "26px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {showPassword ? <GoEye size={20} /> : <GoEyeClosed size={20} />}
            </span>
          </div>
          {errors.password && (
            <p className="error">{errors.password.message}</p>
          )}

          <label className="label">First Name <span className="staric">*</span></label>
          <div className="input-container">
            <input
              type="text"
              className="input"
              placeholder="First Name"
              {...register("firstName", {
                required: "First name is required",
                minLength: {
                  value: 2,
                  message: "First name must be at least 2 characters",
                },
              })}
            />
          </div>
          {errors.firstName && (
            <p className="error">{errors.firstName.message}</p>
          )}

          <label className="label">Last Name <span className="staric">*</span></label>
          <div className="input-container">
            <input
              type="text"
              className="input"
              placeholder="Last Name"
              {...register("lastName", {
                required: "Last name is required",
                minLength: {
                  value: 2,
                  message: "Last name must be at least 2 characters",
                },
              })}
            />
          </div>
          {errors.lastName && (
            <p className="error">{errors.lastName.message}</p>
          )}

          <div className="location-flex">
            <div style={{ width: "50%" }}>
              <label className="label">Country <span className="staric">*</span></label> <br />
              {/* <select
                className="input" 
                {...register("country", { required: "Country is required" })}
                style={{ color: watch("country") === "" ? "black" : "#B8B8B8" }}
                onChange={handleCountryChange}
                
              > */}
              <select
                className="input"
                {...register("country", {
                  required: "Country is required",
                  onChange: (e) => {
                    handleCountryChange(e);
                    // Trigger revalidation on change
                    trigger("country");
                  },
                })}
                style={{ color: watch("country") === "" ? "black" : "" }}
              >
                <option value="" className="default-option">
                  Select Country
                </option>
                {statesData.map((country) => (
                  <option key={country.id} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
              {errors.country && (
                <p className="error">{errors.country.message}</p>
              )}
            </div>
            <div style={{ width: "50%" }}>
              <label className="label">City <span className="staric">*</span></label>
              <select
                className="input"
                {...register("city", { required: "City is required" })}
                style={{ color: watch("city") === "" ? "black" : "" }}
              >
                <option value="">Select City</option>
                {availableCities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
              {errors.city && <p className="error">{errors.city.message}</p>}
            </div>
          </div>
          <label className="label">Location <span className="staric">*</span></label>
          <div className="input-container">
            <input
              type="text"
              className="input"
              placeholder="Location"
              {...register("location", { required: "Location is required" })}
            />
          </div>
          {errors.location && (
            <p className="error">{errors.location.message}</p>
          )}

          <label className="label">Upload Photo <span className="staric">*</span></label>
          <div className="input-container">
            <div
              className="input"
              onClick={() => document.getElementById("file-upload").click()} // Trigger file input click
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                color: watch("photo") === "" ? "black" : "#B8B8B8",
                justifyContent: "space-between",
              }}
            >
              {photo
                ? `Selected file: ${photo.name}`
                : "Click to upload an image"}

              <img src={Image} alt="" />
            </div>
            <input
              type="file"
              id="file-upload" // Ensure this matches the ID in the onClick
              accept="image/png, image/jpeg" // Specify accepted formats
              onChange={(e) => validateFile(e.target.files[0])}
              style={{ display: "none" }} // Keep the input hidden
            />
            {upload && <img src={upload} alt="" className="upload" />}
          </div>

          {errors.photo && <p className="error">{errors.photo.message}</p>}
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              type="submit"
              className="button"
              disabled={loading}
              style={{ marginBottom: "20px" }}
            >
              {loading ? "Registering..." : "REGISTER"}
            </button>

            <button
              type="button"
              className="already-button"
              onClick={() => Login()}
            >
              Already Have Account
            </button>
          </div>
        </form>
      </Register>
    </div>
  );
};

export default Resgister;
