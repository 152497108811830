

import styled from 'styled-components';


export const StyledMain = styled.main`
     width: 100%;
     height: 100vh;

     .fixed-image-container {
  position: fixed; /* Fix the container to the viewport */
  top: 0;
  left: 0;
  width: 50%; /* Cover half of the screen */
  height: 100vh; /* Full height of the viewport */
  overflow: hidden; /* Prevent any overflow */
  z-index: -1; /* Keep it behind other content */
}

.fixed-splash-image {
  width: 100%; /* Ensure it covers the container */
  height: 100%; /* Full height */
  object-fit: fill; /* Maintain aspect ratio and cover the container */
}

  /* .login-background{
    background-color: #F1F1F1;
  } */
`;
