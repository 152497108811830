import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import styled from "styled-components";
import statesData from "../../../containers/dashboard/missing-persons/countries"; // Adjust the path as needed
import MyList from "components/generics";
import { useNavigate } from "react-router-dom";

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Baloo Tamma 2";
  font-weight: 600;
  font-size: 30px;
  margin: 0;
`;

const ModalBody = styled.div`
  margin: 20px 0;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-family: "Baloo Tamma 2";

  .back-color{
    background: linear-gradient(90deg, rgba(45, 201, 145, 1) 0%, rgba(23, 131, 47, 1) 100%);
  }
  .back-color:hover {
    background: linear-gradient(90deg, rgba(23, 131, 47, 1) 0%, rgba(45, 201, 145, 1) 100%);
    transition: all 0.4s;
  }
  .reset-color{
    background-color: #E9BB45;
    border: 0px;
    color: white;
  }
  .reset-color:hover{
    color: #E9BB45;
    border: 1px solid  rgba(23, 131, 47, 1);
    background-color: #FFFFFF;
    transition: all 0.4s;
  }
`;

const FilterSelect = styled.select`
  width: 100%;
  background-color: rgba(248, 248, 248);
  border: 0px;
  height: 48px;
  border-radius: 10px;
  font-family: "Baloo Tamma 2";
  font-weight: 600;
  font-size: 18px;
  color: rgba(65, 65, 65, 1);
  padding: 12px;
  margin-top: 10px;
`;

const FilterModal = ({ open, handleClose, handleFilter }) => {
  const [selectedCountry, setSelectedCountry] = useState("");
  let [selectedStateId, setSelectedStateId] = useState("");
  let [selectedCityId, setSelectedCityId] = useState("");

  const { fetchUsers, AllMyList } = MyList();

  // Assuming statesData contains countries with states and cities
  const countries = statesData;
  const navigate = useNavigate()

  if (!open) return null;

  const handleApplyFilter = () => {
    
    
      let city = countries
        .flatMap((country) => country.states)
        .flatMap((state) => state.cities)
        .find((city) => city.id.toString() === selectedCityId);
      city? selectedCityId = city.name : selectedCityId = ''
     
 
    handleFilter(selectedCountry, selectedStateId, selectedCityId);
    setSelectedCountry("");
    setSelectedStateId("");
    setSelectedCityId("");
    handleClose();
    navigate("/")
  };

  const handleReset = async () => {
    setSelectedCountry("");
    setSelectedStateId("");
    setSelectedCityId("");

    fetchUsers();
    handleClose();
    AllMyList();
  };

  // Prepare states and cities for the selects
  const availableStates = selectedCountry
    ? countries.find((country) => country.name === selectedCountry)?.states ||
      []
    : countries.flatMap((country) => country.states);

  const availableCities = selectedStateId
    ? availableStates.find((state) => state.id.toString() === selectedStateId)
        ?.cities || []
    : availableStates.flatMap((state) => state.cities);

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <p className="heading m-0">Filter Data</p>
          <IoCloseSharp
            className="close-icon"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          />
        </ModalHeader>
        <ModalBody>
          <div
            className="filter-selects"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <FilterSelect
              value={selectedCountry}
              onChange={(e) => {
                setSelectedCountry(e.target.value);
                setSelectedStateId("");
                setSelectedCityId("");
              }}
            >
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country.id} value={country.name}>
                  {country.name}
                </option>
              ))}
            </FilterSelect>

            <FilterSelect
              value={selectedStateId}
              onChange={(e) => {
                setSelectedStateId(e.target.value);
                setSelectedCityId("");
              }}
            >
              <option value="">Select State</option>
              {availableStates.length > 0 ? (
                availableStates.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No states available
                </option>
              )}
            </FilterSelect>

            <FilterSelect
              value={selectedCityId}
              onChange={(e) => setSelectedCityId(e.target.value)}
            >
              <option value="">Select City</option>
              {availableCities.length > 0 ? (
                availableCities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No cities available
                </option>
              )}
            </FilterSelect>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="confirm-btn back-color"
            style={{
              width: "25%",
              border: "0px",
              color: "white",
              fontFamily: "Baloo Tamma 2",
              fontSize: "18px",
              height: "40px",
              borderRadius: "6px",
              fontWeight: "600",
            }}
            onClick={handleApplyFilter}
          >
            Filter
          </button>
          <button
            className="cancel-btn reset-color"
            onClick={handleReset}
            style={{
              width: "25%", 
              fontFamily: "Baloo Tamma 2",
              fontSize: "18px",
              height: "40px",
              borderRadius: "6px",
              fontWeight: "600",
            }}
          >
            Reset
          </button>
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default FilterModal;
