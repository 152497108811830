import React from "react";
import { Main } from "./style";
import Image from "../../../assets/images/svgs/profile/image.svg";
import flag from "../../../assets/images/svgs/home/flag.svg";
import location from "../../../assets/images/svgs/home/location.svg";
import location2 from "../../../assets/images/svgs/home/location.png";
import country2 from "../../../assets/images/svgs/home/country.png";
import { useLocation } from "react-router-dom";
const Profile = () => {
  const locations = useLocation();
  const { id, fullName, photo, location, country,story,state } = locations.state || {};
  return (
    <Main>
      <div className="backimg">
        <div className="img">
          <img src={photo} alt="image" className="main-img" />
        </div>
        <div className="name">
          <p>{fullName}</p>
        </div>

        <div className="container-fluid">
          <div className="flex">
            <div>
              <p className="cards-location">
                <img src={location2} alt="location" className="icon" />
                <span> {location} , {state}</span>
              </p>
            </div>
            <div>
              <p className="cards-location">
                <img src={country2} alt="flag" className="icon" />
                <span> {country}</span>
              </p>
            </div>
          </div>

          <div className="pading">
            <p className="story">Story</p>

            <div className="card card-border">
              <p className="card-padding">
               {story}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Profile;
