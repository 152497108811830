import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  .main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .logo {
    width: 153.24px;
    height: 53.55px;
    cursor: pointer;
  }

  /* Burger Button */
  .burger {
    display: none;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    height: 24px;
    width: 30px;
  }

  .burger span {
    display: block;
    width: 100%;
    height: 3px;
    background: rgba(65, 65, 65, 1);
    transition: all 0.3s ease;
  }

  .burger.open span:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
  }

  .burger.open span:nth-child(2) {
    opacity: 0;
  }

  .burger.open span:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }

  .main-flex {
    display: flex;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 18px;
    width: 100%;
  }

  .menu-open {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    .burger {
      display: flex;
    }
    .main-flex {
      display: none;
    }
    .menu-open {
      display: flex;
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      background-color: #fff;
      padding: 10px;
      flex-direction: column-reverse;
        align-items: start;
        z-index: 9999999;
    }
  }
  .main-flex2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  .main-photo{
    width: 35px;
    height: 35px;
    border-radius: 20px;
  }
  .name{
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 18px;
    color: rgba(65, 65, 65, 1);
    margin: 0px;
  }
  .background{
    background-color: #E9BB45;
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 18px;
    color: #17832f;
    margin: 0px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    padding-top: 13px;
    cursor: pointer;
    @media (max-width:768px) {
      width: 50%; 
      font-size: 14px;
    }
  }
  .background:hover{
    background-color:#17832f;
    color: #E9BB45;
    transition: all   0.3s;
  }
  .input {
    width: 100%;
    background-color: rgba(234, 234, 234, 1);
    border: 0px;
    height: 35px;
    border-radius: 10px;
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    color: rgba(65, 65, 65, 1);
    /* padding: 10px;
    padding-top: 15px; */
    padding: 7px;
    outline: none;
  }

  .dropdown-container {
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .dropdown-options {
    position: absolute;
    top: 120%;
    right: 0;
    width: 180px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    z-index: 1;
    animation: fadeIn 0.3s ease;
  }

  .dropdown-option {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Baloo Tamma 2';
    font-weight: 500;
    color: rgba(65, 65, 65, 1);
    transition: background 0.2s ease;
  }

  .dropdown-option:hover {
    background-color: #f0f0f0;
  }

  .dropdown-img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .arrow-icon img {
    width: 12px;
    height: 12px;
    transition: transform 0.3s ease;
  }

  .active .arrow-icon img {
    transform: rotate(180deg);
  }

  .log-out-btn {
    width: 100%;
    padding: 10px;
    background: linear-gradient(90deg, rgba(45, 201, 145, 1) 0%, rgba(23, 131, 47, 1) 100%);
    color: white;
    border: none;
    border-radius: 8px;
    font-family: 'Baloo Tamma 2';
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    transition: background 0.3s ease;
  }
  .input-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 17%;
    background-color: rgba(234, 234, 234, 1);
    border: 0px;
    height: 35px;
    border-radius: 10px;
    font-family: "Baloo Tamma 2";
    font-weight: 600;
    font-size: 18px;
    color: rgba(65, 65, 65, 1);
    padding: 10px;
    outline: none;
    @media (max-width:768px) {
      width: 50%; 
      font-size: 14px;
    }
  }
  .filter-bg{
    background-color: rgb(234, 234, 234);
    padding: 9px;
    border-radius: 30px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .log-out-btn:hover {
    background: linear-gradient(90deg, rgba(23, 131, 47, 1) 0%, rgba(45, 201, 145, 1) 100%);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
