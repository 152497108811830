import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStyles from "./styles/index";
import { configureHeaders } from "libs/http-service/interceptors/http.interceptors";
import { configureInterceptors } from "libs/http-service/interceptors/http.interceptors";
import { SearchProvider } from "context";
const root = ReactDOM.createRoot(document.getElementById("root"));

configureHeaders();
configureInterceptors();

root.render(
  <React.StrictMode>
    <GlobalStyles />
    <SearchProvider>
      <App />
    </SearchProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
