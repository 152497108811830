import styled from 'styled-components';

export const Register = styled.div`
  /* width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
  background-color: #F1F1F1; */
  width: 100%;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;  
  background-color: #F1F1F1; /* Background color for the entire screen */
  padding: 20px 0; /* Add some padding to ensure content isn't too close to the edges */  
  form {
    width: 100%;
    max-width: 600px;

    padding: 20px;
    box-sizing: border-box; 
  }
  .form-padding{
    padding: "0px 129px"
    
  }

  .welcome-text {
    font-family: 'Baloo Tamma 2';
    font-weight: 600;
    font-size: 40px;
    color: black; 
    margin: 0px;
  }
  .welcome-text2 {
    font-family: 'Baloo Tamma 2';
    font-weight: 500;
    font-size: 16px;
    color: 16px;
    margin-bottom: 35px;
  }

  .label {
    font-family: 'Baloo Tamma 2';
    font-weight: 600;
    font-size: 18px;
    color: #414141; 
  }

  .input-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px; */
  @media (max-width:768px) {
    flex-direction: column;
  }
}

.input {
  width: 100%;
  padding-left: 40px;  
  border: 0px;
  outline: none;
  font-family: 'Baloo Tamma 2';
  color: black;
  height: 53.59px;
  border-radius: 10px;
  padding: 0px 15px;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  background-color: #FFFFFF;
  padding-top: 4px;
}
.default-option {
  color: #B8B8B8;
}

.location-flex{
    width: 100%;
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
}
.input-container {
  gap: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px; */
}
.input-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-70%);
    width: 30px;
    height: 30px;
    pointer-events: none;
}



  .button {
    background: linear-gradient(
    90deg,
    rgba(45, 201, 145, 1) 0%,
    rgba(23, 131, 47, 1) 100%
  );
    color: white;
    width: 100%;
    height: 38px;
    border-radius: 10px;
    border: 0;
    font-family: 'Baloo Tamma 2';
    font-weight: 600;
    font-size: 19px;
    outline: none;
    cursor: pointer;
    height: 53.59px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 7px;
  }
  .button:hover {
    background: linear-gradient(90deg, rgba(23, 131, 47, 1) 0%, rgba(45, 201, 145, 1) 100%);
    transition: all 0.4s;
  }
  .already-button{
    background-color: #E9BB45;
    color: white;
    width: 100%;
    height: 38px;
    border-radius: 10px;
    border: 0;
    font-family: 'Baloo Tamma 2';
    font-weight: 600;
    font-size: 19px;
    outline: none;
    cursor: pointer;
    height: 53.59px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 7px;
  }
  .already-button:hover{
    color: #E9BB45;
    border: 1px solid  rgba(23, 131, 47, 1);
    background-color: #FFFFFF;
    transition: all 0.4s;
  }
  .error{
  font-family: 'Baloo Tamma 2';
    font-weight: 500;
    font-size: 16px;
    color: red;
}

.input-file {
  display: none; /* Hide the file input */
}

.input-icon {
  cursor: pointer; /* Change cursor to indicate clickable */
}
.upload{
  width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
}
.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    display: none;
  }
  @media (max-width: 991px) {
    .logo {
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
    }
    .welcome-text{
    font-size: 30px;
  }
}


 
`;
