import React, { useEffect } from "react";
import img from "../../assets/images/others/blank-page/Frame.svg";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import styled from "styled-components";

const BlankPage = () => {
  
  const Verify = styled.div`
  
  width: 100%;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;  
  background-color: #F1F1F1; /* Background color for the entire screen */
  padding: 20px 0;
  `
  
  
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        if (user.emailVerified) {
          navigate("/auth");  
        }
      }
    });

    return () => unsubscribe(); 
  }, [navigate]);


  return (
    <Verify>
    <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',  
      justifyContent: 'center',  
      textAlign: 'center',  
      width: "100%",
      maxWidth: "478px",
    }}
  >
    <div style={{ marginRight: "70px" }}>
      <img src={img} alt="Vendor Email Prompt" />
    </div>
    <h1
      style={{
        fontFamily: "Baloo Tamma 2",
        fontSize: "24px",
        fontWeight: "600",
        marginTop: "20px",
        color: "#1e5e3b",
      }}
    >
    We have sent you an email. Please verify it to proceed with your request. After verification, refresh the page!
     
    </h1>
  </div>
  </Verify>
  );
};

export default BlankPage;

// import React, { useEffect } from "react";
// import img from "../../assets/images/others/blank-page/Frame.png";
// import { useNavigate } from "react-router-dom";
// import { auth } from "../../firebase";
// import styled from "styled-components";

// const BlankPage = () => {
//   const Verify = styled.div`
//     width: 100%;
//     min-height: 100vh; /* Ensure it covers the full viewport height */
//     display: flex;
//     justify-content: center;
//     align-items: center;  
//     background-color: #F1F1F1; /* Background color for the entire screen */
//     padding: 20px 0; /* Add some padding to ensure content isn't too close to the edges */
//   `;
  
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user) {
//         if (user.emailVerified) {
//           navigate("/auth");  
//         }
//       }
//     });

//     return () => unsubscribe(); 
//   }, [navigate]);

//   return (
//     <Verify>
//       <div
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//           textAlign: 'center',
//         }}
//       >
//         <div style={{ marginBottom: "20px" }}>
//           <img src={img} alt="Vendor Email Prompt" style={{width: '50%'}} />
//         </div>
//         <h1
//           style={{
//             fontFamily: "Baloo Tamma 2",
//             fontSize: "24px",
//             fontWeight: "600",
//             color: "#1e5e3b",
//             margin: "0", // Remove margin to keep the heading centered
//           }}
//         >
//           We have sent you an email. Please verify it to proceed with your request. After verification, refresh the page!
//         </h1>
//         <div>
        
//         </div>
//       </div>
    
//     </Verify>
//   );
// };

// export default BlankPage;
