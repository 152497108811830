import React, { useState, useEffect } from "react";
import { Profile } from "./style";
import { Register } from "./style";
import Image from "../../../assets/images/svgs/register/OBJECTS.svg";
import { auth, db, storage } from "../../../firebase"; // Firebase instances
import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import statesData from "../../dashboard/missing-persons/countries";
import { GoEye, GoEyeClosed } from "react-icons/go";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";

const UserProfile = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [upload, setUpload] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [availableCities, setAvailableCities] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrent, setShowCurrent] = useState(false);
  const navigate = useNavigate();
  // Assumes the user is already authenticated
  //  const userId = auth.currentUser?.uid;
  const userId = localStorage.getItem("UID");

  // Fetch existing user data from Firestore
  useEffect(() => {
    // debugger
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDoc = await getDoc(doc(db, "users", userId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setValue("email", userData.email);
            setValue("firstName", userData.firstName);
            setValue("lastName", userData.lastName);
            setValue("country", userData.country);
            setValue("city", userData.city);
            setValue("location", userData.location);
            setPhoto(userData.photo);

            // Set selected country and fetch cities based on user's saved country
            setSelectedCountry(userData.country);
            const countryData = statesData.find(
              (country) => country.name === userData.country
            );
            if (countryData) {
              const cities = countryData.states.flatMap((state) =>
                state.cities.map((city) => city.name)
              );
              setAvailableCities(cities);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
    fetchUserData();
  }, [userId, setValue]);

  // const onSubmit = async (data) => {
  //   setLoading(true);
  //   try {
  //     let photoURL = photo;

  //     // Upload the photo to Firebase Storage if a new one is selected
  //     if (photo && photo instanceof File) {
  //       const storageRef = ref(storage, `users/${userId}/profile.jpg`);
  //       await uploadBytes(storageRef, photo);
  //       photoURL = await getDownloadURL(storageRef);
  //     }

  //     // Prepare updated user data
  //     const updatedUserData = {
  //       email: data.email,
  //       firstName: data.firstName,
  //       lastName: data.lastName,
  //       country: data.country,
  //       city: data.city,
  //       location: data.location,
  //       photo: photoURL,
  //       password: data.password,
  //       updatedAt: new Date().toISOString(),
  //     };

  //     // Update user data in Firestore
  //     await updateDoc(doc(db, "users", userId), updatedUserData);

  //     // Update local storage with the new user data
  //     localStorage.setItem("userPhoto", photoURL);
  //     localStorage.setItem("username", `${data.firstName} ${data.lastName}`);

  //     toast.success("Profile updated successfully!", {
  //       position: "top-right",
  //       autoClose: 3000,
  //     });
  //   } catch (error) {
  //     console.error("Error updating profile:", error);
  //     toast.error("Failed to update profile. Please try again.", {
  //       position: "top-right",
  //       autoClose: 3000,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let photoURL = photo;

      // Upload the photo to Firebase Storage if a new one is selected
      if (photo && photo instanceof File) {
        const storageRef = ref(storage, `users/${userId}/profile.jpg`);
        await uploadBytes(storageRef, photo);
        photoURL = await getDownloadURL(storageRef);
      }

      // Reauthenticate if the password field is filled
      if (data.password) {
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(
          user.email,
          data.password
        ); // Add an oldPassword field to your form
        console.log(user.email, data.password)

        // Reauthenticate the user
        await reauthenticateWithCredential(user, credential);

        // Update the password
        await updatePassword(user, data.newPassword); // newPassword should also be part of your form data
      }

      // Prepare updated user data
      const updatedUserData = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        country: data.country,
        city: data.city,
        location: data.location,
        photo: photoURL,
        password:data.newPassword,
        updatedAt: new Date().toISOString(),
      };

      // Update user data in Firestore
      await updateDoc(doc(db, "users", userId), updatedUserData);

      // Update local storage with the new user data
      localStorage.setItem("userPhoto", photoURL);
      localStorage.setItem("username", `${data.firstName} ${data.lastName}`);

      toast.success("Profile updated successfully!", {
        position: "top-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile. Please try again.", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const validateFile = (file) => {
    const validTypes = ["image/jpeg", "image/png"]; // Define valid types
    if (file) {
      if (!validTypes.includes(file.type)) {
        setError("photo", {
          type: "manual",
          message: "Only .png, .jpg, or .jpeg files are accepted.",
        });
        setPhoto(null);
      } else {
        const filePath = URL.createObjectURL(file);
        console.log(filePath);

        clearErrors("photo");
        setUpload(filePath);
        setPhoto(file);
      }
    }
  };

  // Function to handle the country selection
  const handleCountryChange = (e) => {
    const selected = e.target.value;
    setSelectedCountry(selected);
    setValue("city", "");
    const countryData = statesData.find((country) => country.name === selected);

    if (countryData) {
      const cities = countryData.states.flatMap((state) =>
        state.cities.map((city) => city.name)
      );
      setAvailableCities(cities);
    } else {
      setAvailableCities([]);
    }
  };

  return (
    <Profile>
      <ToastContainer />
      <Register>
        <form onSubmit={handleSubmit(onSubmit)} className="form-padding">
          <p className="welcome-text">Update Your Profile</p>

          <label className="label">Email <span className="staric">*</span></label>
          <div className="input-container">
            <input
              type="email"
              className="input"
              placeholder="Myname@gmail.com"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address",
                },
              })}
              disabled
            />
          </div>
          {errors.email && <p className="error">{errors.email.message}</p>}

          <label className="label">First Name <span className="staric">*</span></label>
          <div className="input-container">
            <input
              type="text"
              className="input"
              placeholder="Example Name"
              {...register("firstName", {
                required: "First name is required",
                minLength: {
                  value: 2,
                  message: "First name must be at least 2 characters",
                },
              })}
            />
          </div>
          {errors.firstName && (
            <p className="error">{errors.firstName.message}</p>
          )}

          <label className="label">Last Name <span className="staric">*</span></label>
          <div className="input-container">
            <input
              type="text"
              className="input"
              placeholder="Example Name"
              {...register("lastName", {
                required: "Last name is required",
                minLength: {
                  value: 2,
                  message: "Last name must be at least 2 characters",
                },
              })}
            />
          </div>
          {errors.lastName && (
            <p className="error">{errors.lastName.message}</p>
          )}
          <label className="label">Current Password</label>
          <div className="input-container" style={{position: "relative"}}>
            <input
              type={showPassword ? "text" : "password"}
              className="input"
              placeholder="Current Password"
              {...register("password")}
            />
            <span
              style={{
                position: "absolute",
                right: "15px",
                top: "15px",
                cursor: "pointer",
              }}
              onClick={() => setShowPassword(!showPassword)} // Toggle showPassword on icon click
            >
              {showPassword ?<GoEye size={20}/>  :<GoEyeClosed size={20}/> }
            </span>
          </div>
          {errors.password && (
            <p className="error">{errors.password.message}</p>
          )}

          <label className="label">New Password</label>
          <div className="input-container" style={{position: "relative"}}>
          <input
            type={showCurrent ? "text" : "password"}
            className="input"
            placeholder="New Password"
            {...register("newPassword")}
          />
           <span
              style={{
                position: "absolute",
                right: "15px",
                top: "15px",
                cursor: "pointer",
              }}
              onClick={() => setShowCurrent(!showCurrent)} // Toggle showPassword on icon click
            >
              {showCurrent ?<GoEye size={20}/>  :<GoEyeClosed size={20}/> }
            </span>
            </div>
            
          <div className="location-flex">
            <div style={{ width: "50%" }}>
              <label className="label">Country <span className="staric">*</span></label> <br />
              <select
                className="input"
                {...register("country", { required: "Country is required" })}
                onChange={handleCountryChange}
              >
                <option value="">Select Country</option>
                {statesData.map((country) => (
                  <option key={country.id} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
              {errors.country && (
                <p className="error">{errors.country.message}</p>
              )}
            </div>
            <div style={{ width: "50%" }}>
              <label className="label">City <span className="staric">*</span></label>
              <select
                className="input"
                {...register("city", { required: "City is required" })}
                value={watch("city")}
              >
                <option value="">Select City </option>
                {availableCities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
              {errors.city && <p className="error">{errors.city.message}</p>}
            </div>
          </div>

          <label className="label">Location <span className="staric">*</span></label>
          <div className="input-container">
            <input
              type="text"
              className="input"
              placeholder="Example Location"
              {...register("location", { required: "Location is required" })}
            />
          </div>
          {errors.location && (
            <p className="error">{errors.location.message}</p>
          )}

          <label className="label">Upload Photo <span className="staric">*</span></label>
          <div className="input-container">
            <div
              className="input"
              onClick={() => document.getElementById("file-upload").click()}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {photo && photo instanceof File
                ? `Selected file: ${photo.name}`
                : "Click to upload an image"}

              <img src={Image} alt="" />
            </div>

            <input
              type="file"
              id="file-upload"
              accept="image/png, image/jpeg"
              onChange={(e) => validateFile(e.target.files[0])}
              style={{ display: "none" }}
            />

            {/* Show preview of uploaded photo */}
            {upload && (
              <img src={upload} alt="New Upload Preview" className="upload" />
            )}

            {/* Show previous photo if available */}
            {!upload && photo && typeof photo === "string" && (
              <img
                src={photo}
                alt="Previous Profile Photo"
                className="upload"
              />
            )}
          </div>

          {errors.photo && <p className="error">{errors.photo.message}</p>}

          {errors.photo && <p className="error">{errors.photo.message}</p>}

          <div style={{ textAlign: "center" }}>
            <button type="submit" className="button" disabled={loading}>
              {loading ? "Updating..." : "Update Profile"}
            </button>
          </div>
        </form>
      </Register>
    </Profile>
  );
};

export default UserProfile;
